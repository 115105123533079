import Setting from '@/setting';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'iHeaderI18n',
  props: {
    outside: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languages: Setting.i18n.list
    };
  },
  computed: {
    ...mapState('admin/i18n', ['locale']),
    ...mapState('admin/layout', ['isMobile'])
  },
  methods: {
    ...mapActions('admin/i18n', ['setLocale']),
    handleClick(locale) {
      if (locale === this.locale) return;
      this.setLocale({
        locale,
        vm: this
      });
    }
  }
};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in i-layout-header-trigger-nohover"
};
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = {
  class: "i-layout-header-trigger i-layout-header-trigger-min"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_Input = _resolveComponent("Input");
  const _component_Col = _resolveComponent("Col");
  const _component_Row = _resolveComponent("Row");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _ctx.isDesktop ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createElementVNode("input", {
    class: "i-layout-header-search",
    type: "text",
    placeholder: _ctx.$t('basicLayout.search.placeholder')
  }, null, 8 /* PROPS */, _hoisted_2)])) : (_openBlock(), _createBlock(_component_Dropdown, {
    key: 1,
    trigger: "click",
    class: "i-layout-header-search-drop",
    ref: "dropdown"
  }, {
    list: _withCtx(() => [_createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [_createVNode(_component_Row, {
        align: "middle"
      }, {
        default: _withCtx(() => [_createVNode(_component_Col, {
          flex: "auto",
          class: "ivu-pl-4"
        }, {
          default: _withCtx(() => [_createVNode(_component_Input, {
            size: "large",
            prefix: "ios-search",
            placeholder: _ctx.$t('basicLayout.search.placeholder')
          }, null, 8 /* PROPS */, ["placeholder"])]),
          _: 1 /* STABLE */
        }), _createVNode(_component_Col, {
          flex: "80px",
          class: "ivu-text-center"
        }, {
          default: _withCtx(() => [_createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = (...args) => $options.handleCloseSearch && $options.handleCloseSearch(...args))
          }, _toDisplayString(_ctx.$t('basicLayout.search.cancel')), 1 /* TEXT */)]),

          _: 1 /* STABLE */
        })]),

        _: 1 /* STABLE */
      })]),

      _: 1 /* STABLE */
    })]),

    default: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_Icon, {
      type: "ios-search"
    })])]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */));
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_menu_head_title = _resolveComponent("i-menu-head-title");
  const _component_BreadcrumbItem = _resolveComponent("BreadcrumbItem");
  const _component_Breadcrumb = _resolveComponent("Breadcrumb");
  return !$data.isLimit ? (_openBlock(), _createBlock(_component_Breadcrumb, {
    key: 0,
    class: "i-layout-header-breadcrumb",
    ref: "breadcrumb"
  }, {
    default: _withCtx(() => [_createVNode(_component_BreadcrumbItem, null, {
      default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
        item: $options.topItem,
        "hide-icon": !_ctx.showBreadcrumbIcon
      }, null, 8 /* PROPS */, ["item", "hide-icon"])]),
      _: 1 /* STABLE */
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.items, item => {
      return _openBlock(), _createBlock(_component_BreadcrumbItem, {
        key: item.path
      }, {
        default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
          item: item,
          "hide-icon": !_ctx.showBreadcrumbIcon
        }, null, 8 /* PROPS */, ["item", "hide-icon"])]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */);
    }), 128 /* KEYED_FRAGMENT */)), _createVNode(_component_BreadcrumbItem, null, {
      default: _withCtx(() => [_createVNode(_component_i_menu_head_title, {
        item: $options.siderMenuObject[_ctx.activePath],
        "hide-icon": !_ctx.showBreadcrumbIcon
      }, null, 8 /* PROPS */, ["item", "hide-icon"])]),
      _: 1 /* STABLE */
    })]),

    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */)) : _createCommentVNode("v-if", true);
}
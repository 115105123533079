import { mapState, mapMutations } from 'vuex';
export default {
  name: 'iHeaderSetting',
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapState('admin/layout', ['siderTheme', 'headerTheme', 'headerStick', 'headerMenu', 'siderFix', 'headerFix', 'headerHide', 'menuAccordion', 'showSiderCollapse', 'tabs', 'showTabsIcon', 'tabsFix', 'showBreadcrumb', 'showBreadcrumbIcon', 'showReload', 'showI18n', 'showCollapseMenuTitle'])
  },
  methods: {
    ...mapMutations('admin/layout', ['updateLayoutSetting']),
    showSetting() {
      this.visible = true;
    },
    handleChangeSetting(key, value) {
      this.updateLayoutSetting({
        key,
        value
      });
    }
  }
};
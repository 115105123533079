import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/logo-small.png';
import _imports_1 from '@/assets/images/logo.png';
import _imports_2 from '@/assets/images/logo-dark.png';
const _hoisted_1 = {
  key: 0,
  src: _imports_0
};
const _hoisted_2 = {
  key: 1,
  src: _imports_1
};
const _hoisted_3 = {
  key: 2,
  src: _imports_2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_i_link = _resolveComponent("i-link");
  return _openBlock(), _createBlock(_component_i_link, {
    class: _normalizeClass(["i-layout-header-logo", {
      'i-layout-header-logo-stick': !_ctx.isMobile
    }]),
    to: "/"
  }, {
    default: _withCtx(() => [_ctx.isMobile ? (_openBlock(), _createElementBlock("img", _hoisted_1)) : _ctx.headerTheme === 'light' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}
import iMenuSideTitle from './menu-title';
import clickItem from '../mixins/click-item';
import siderMenuBadge from '../mixins/sider-menu-badge';
export default {
  name: 'iMenuSideItem',
  components: {
    iMenuSideTitle
  },
  mixins: [clickItem, siderMenuBadge],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  }
};
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_Badge = _resolveComponent("Badge");
  const _component_Tooltip = _resolveComponent("Tooltip");
  return _openBlock(), _createBlock(_component_Tooltip, {
    content: $options.tooltipContent,
    transfer: ""
  }, {
    default: _withCtx(() => [_createElementVNode("span", {
      class: "i-layout-header-trigger i-layout-header-trigger-min",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.handleOpenLog && $options.handleOpenLog(...args))
    }, [_createVNode(_component_Badge, {
      count: _ctx.lengthError === 0 ? null : _ctx.lengthError,
      "overflow-count": 99,
      dot: $options.showDot,
      offset: $options.showDot ? [26, 2] : [20, 0]
    }, {
      default: _withCtx(() => [_createVNode(_component_Icon, {
        custom: "i-icon i-icon-record"
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "dot", "offset"])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["content"]);
}
export default {
  'zh-CN': {
    register: {
      title: '注册',
      submit: '注册',
      other: '使用已有账户登录'
    }
  },
  'en-US': {
    register: {
      title: 'Register',
      submit: 'Register',
      other: 'Already have an account?'
    }
  }
};
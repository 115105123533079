// 菜单，顶栏

export default [{
  path: '/',
  title: '首页',
  icon: 'md-home',
  hideSider: false,
  name: 'home'
}, {
  path: '/log',
  title: '日志',
  icon: 'md-locate',
  hideSider: true,
  name: 'system'
}];
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_NotificationTab = _resolveComponent("NotificationTab");
  const _component_Notification = _resolveComponent("Notification");
  return _openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_Notification, {
    wide: _ctx.isMobile,
    "badge-props": $data.badgeProps,
    class: _normalizeClass(["i-layout-header-notice", {
      'i-layout-header-notice-mobile': _ctx.isMobile
    }])
  }, {
    icon: _withCtx(() => [_createVNode(_component_Icon, {
      custom: "i-icon i-icon-notification"
    })]),
    default: _withCtx(() => [_createVNode(_component_NotificationTab, {
      title: "通知"
    }), _createVNode(_component_NotificationTab, {
      title: "消息"
    }), _createVNode(_component_NotificationTab, {
      title: "待办"
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["wide", "badge-props", "class"])]);
}
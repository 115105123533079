import iMenuSideTitle from './menu-title';
import clickItem from '../mixins/click-item';
import Setting from '@/setting';
import { mapState } from 'vuex';
export default {
  name: 'iMenuSideCollapse',
  components: {
    iMenuSideTitle
  },
  mixins: [clickItem],
  props: {
    menu: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否是第一级，区分在于左侧和展开侧
    topLevel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('admin/layout', ['siderTheme', 'showCollapseMenuTitle', 'bodyHeight']),
    ...mapState('admin/menu', ['activePath', 'openNames']),
    dropdownClasses() {
      return {
        'i-layout-menu-side-collapse-top': this.topLevel,
        'i-layout-menu-side-collapse-dark': this.siderTheme === 'dark'
      };
    },
    menuItemClasses() {
      return ['ivu-menu-item i-layout-menu-side-collapse-top-item', {
        'ivu-menu-item-selected ivu-menu-item-active': this.openNames.indexOf(this.menu.path) >= 0 // -active 在高亮时，有背景
      }];
    },

    // 菜单过多时，限高滚动
    dropdownMenuMaxHeight() {
      return {
        'max-height': `calc(${this.bodyHeight}px - ${Setting.headerHeight}px - 32px)`
      };
    }
  }
};
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  class: "i-layout-menu-head-title"
};
const _hoisted_2 = {
  key: 0,
  class: "i-layout-menu-head-title-icon"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "i-layout-menu-head-title-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Icon = _resolveComponent("Icon");
  const _component_Badge = _resolveComponent("Badge");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [($props.item.icon || $props.item.custom || $props.item.img) && !$props.hideIcon ? (_openBlock(), _createElementBlock("span", _hoisted_2, [$props.item.icon ? (_openBlock(), _createBlock(_component_Icon, {
    key: 0,
    type: $props.item.icon
  }, null, 8 /* PROPS */, ["type"])) : $props.item.custom ? (_openBlock(), _createBlock(_component_Icon, {
    key: 1,
    custom: $props.item.custom
  }, null, 8 /* PROPS */, ["custom"])) : $props.item.img ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    src: $props.item.img
  }, null, 8 /* PROPS */, _hoisted_3)) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.tTitle($props.item.title)), 1 /* TEXT */), $props.badge && $options.badgeData ? (_openBlock(), _createBlock(_component_Badge, _mergeProps({
    key: 1,
    class: "i-layout-menu-head-badge"
  }, $options.badgeData), null, 16 /* FULL_PROPS */)) : _createCommentVNode("v-if", true)]);
}
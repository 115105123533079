export default {
  'zh-CN': {
    login: {
      title: '登录',
      remember: '自动登录',
      forgot: '忘记密码',
      submit: '登录',
      other: '其它登录方式',
      signup: '注册账户'
    }
  },
  'en-US': {
    login: {
      title: 'Login',
      remember: 'Remember me',
      forgot: 'Forgot your password?',
      submit: 'Login',
      other: 'Sign in with',
      signup: 'Sign up'
    }
  }
};
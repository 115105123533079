import { mapGetters } from 'vuex';
export default {
  name: 'iHeaderLog',
  computed: {
    ...mapGetters('admin/log', ['length', 'lengthError']),
    showDot() {
      return !!this.length && this.lengthError === 0;
    },
    tooltipContent() {
      if (!this.length) {
        return '没有日志或异常';
      } else {
        let text = `${this.length} 条日志`;
        if (this.lengthError) text += ` | 包含 ${this.lengthError} 个异常`;
        return text;
      }
    }
  },
  methods: {
    handleOpenLog() {
      this.$router.push({
        name: 'system-log'
      }, () => {});
    }
  }
};